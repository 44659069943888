<template>
  <div @click="handleFollowEmp" :class="{ followed: isFollowed }">
    <button class="button" :class="style">
      <i class="fa-regular fa-heart"></i>
      <span v-if="!isFollowed">{{ $t('follow') }}</span>
      <span v-else>{{ $t('following') }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps(['employerId', 'isFollowed', 'style'])
import { useManageJobStore } from '@/store/manageJob'
import { useAuthStore } from '@/store/auth'
const emit = defineEmits(['setdata'])

const Auth = useAuthStore()
const store = useManageJobStore()
const handleFollowEmp = async () => {
  if (!Auth.isAuth) {
    Auth.LoginModal(true)
  } else {
    try {
      // Access employerId and isFollowed directly from destructured props
      emit('setdata', { id: props.employerId, isFollowed: props.isFollowed })
      await store.followCompany(props.employerId)
      Auth.LoginModal(false)
    } catch (error) {
      console.error('Error follow job:', error)
    }
  }
}
</script>

<style scoped lang="scss">
.button {
  width: 100%;
  display: flex;
  gap: 0.25rem;
  font-weight: 500;
  background-color: var(--primary-100);
  color: #000;
  height: 2.5rem;
  padding: 0 1.5rem;
}
.followed {
  .button {
    color: #fff;
    background-color: var(--primary-900);
  }
  i {
    font-weight: 700;
  }
}
</style>
